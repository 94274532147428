export const environment = {
	production: false,
	env: 'staging',
	channelsAPI: {
		token: 'ep5xycuir9s382ka258bckxdner82f4485c2y6ip9plvlhbgmh4xlxy5x1ejmjed',
		baseUrl: 'https://channelsapi-stg.tagme.com.br',
	},
	liveMenu: {
		url: 'https://live-menu-staging.tagme.com.br/menu',
	},
	cryptoJSKey: '41xiur2viusekrrc',
	// partnerID: '595166383625c506d4868f28', // ProgramaMenu
	partnerID: '63600bcb4a49e96746e30f7d', // Esfera
	// tagmepub: 'https://tagmeimageservice.azurewebsites.net/?q=70&url=https://tagmepub.azureedge.net',
	tagmepub: 'https://tagmepub.azureedge.net',
	googleMapsAPIToken: 'AIzaSyAZ-N7OLjJFVViV6m4Rk5BV7eeuw97DJx8',
}
